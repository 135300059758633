 @import "bootstrap/bootstrap";
 @import "anima";
 @import "fontfaces";
 @import "stripe";
 body {
	 background-color: #fff;
	 line-height: 28px;
	 font-size: 16px;
}
 #content {
	 margin-top: 72px;
}
 .hr {
	 margin-top: 20px;
	 padding-top: 20px;
	 border-top: 1px solid #ccc;
}
 p {
	 margin-bottom: 10px;
	 text-align: left;
	 line-height: 28px;
	 font-size: 16px;
}
 .intro-text p {
	 margin-bottom: 10px;
	 text-align: left;
	 line-height: 28px;
	 font-size: 16px;
}
 .intro-text .text {
	 border-left: 2px solid #00a7c9;
	 padding-left: 20px;
	 margin-top: 30px;
}
 h3 {
	 font-weight: 100;
}
 a.button {
	 background-color: #5000a0;
	 color: #fff;
	 padding: 0.7em 1.5em;
	 border: 0;
	 font-size: 1em;
	 border-radius: 100px;
	 font-family: inherit;
	 -webkit-transition: background-color 0.3s ease-out;
	 transition: background-color 0.3s ease-out;
	 -webkit-appearance: button;
}
 button, a.button {
	 border-radius: 6px;
	 padding-left: 39px;
	 padding-right: 40px;
}
 .action-button {
	 width: 212px;
}
 .action-button.auto {
	 width: auto;
}
 .action-button:disabled {
	 background: #c3c3c3;
}
 .action-button.longer {
	 width: 300px;
}
 .action-button.secondary {
	 background: transparent;
	 color: #5000a0;
	 border: 1px solid #5000a0;
}
 .action-button.secondary:hover {
	 background-color: #00a7c9;
	 color: #fff;
	 border: 1px solid transparent;
}
 label[for="retestCode"] {
	 line-height: 51px;
	 vertical-align: baseline;
	 margin-right: 30px;
	 font-size: 21px;
	 background-color: #fff;
}
 input#retestCode, input#codeForChecking {
	 line-height: 51px;
	 border: none;
	 border-bottom: 1px solid #777;
	 color: #00a7c9;
}
 input#retestCode.error, input#codeForChecking.error {
	 color: #9400ff;
	 border-bottom: 1px solid #9400ff;
}
 .small-info {
	 color: #888;
	 font-size: 11px;
}
 .some-info {
	 color: #555;
	 font-size: 12px;
	 line-height: 18px;
}
 .code-error {
	 color: #9400ff;
}
 .userDataForm input {
	 width: 100%;
	 line-height: 22px;
}
 .instruction-first-p {
	 text-align: left;
	 margin-bottom: 0;
}
 .instruction-table {
	 width: 80%;
	 margin: 0 10%;
	 font-size: 13px;
}
 .instruction-table tr:nth-child(2n) {
	 background-color: #ededed;
}
 .instructions-text {
	 font-size: 14px;
	 text-align: left;
}
 .final-text {
	 font-size: 14px;
	 text-align: left;
}
 .final-text p {
	 margin-bottom: 10px;
}
 label.error {
	 font-size: 14px;
	 color: #9400ff;
	 position: absolute;
	 bottom: -21px;
}
 html {
	 scroll-behavior: smooth;
}
 select.err {
	 color: #9400ff;
}
 .answerLabel {
	 font-size: 14px;
	 text-align: center;
	 font-weight: bold;
}
 .answerLabel span {
	 font-size: 13px;
	 font-style: italic;
	 display: block;
	 text-align: center;
	 font-weight: normal;
}
 .col .MuiFormControlLabel-root {
	 margin: 0;
}
 .item-row:hover {
	 background: #f5f5f5;
}
 .item-row:hover.answered {
	 background: #d3e3d6;
}
 .item-row {
	 border-bottom: 1px solid #ccc;
	 font-size: 14px;
	 padding: 14px 0;
}
 .item-row.answered {
	 background: #f0fff3;
}
 .item-row.highlight {
	 animation: highlight 1000ms ease-out;
}
.item-row.highlight-green {
    animation: highlight 1000ms ease-out;
}
 .item-text {
	 font-size: 16px;
	 margin-bottom: 20px;
}
 @keyframes highlight {
	 0% {
		 background-color: red;
	}
	 100% {
		 background-color: white;
	}
}
@keyframes highlight-green {
    0% {
        background-color: green;
   }
    100% {
        background-color: white;
   }
}
 .questionsHeader.sticky {
	 position: fixed;
	 top: 72px;
	 background: #fff;
	 width: 100%;
	 z-index: 100;
	 margin: -15px;
}
 .meter {
	 display: none;
	 height: 22px;
	/* Can be anything */
	 position: relative;
	 margin: 20px 0;
	/* Just for demo spacing */
	 border: 1px solid #777;
	 -moz-border-radius: 25px;
	 -webkit-border-radius: 25px;
	 border-radius: 25px;
	 padding: 0px;
	 -webkit-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
	 -moz-box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
	 box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}
 .meter > span {
	 display: block;
	 height: 100%;
	 -webkit-border-top-right-radius: 8px;
	 -webkit-border-bottom-right-radius: 8px;
	 -moz-border-radius-topright: 8px;
	 -moz-border-radius-bottomright: 8px;
	 border-top-right-radius: 8px;
	 border-bottom-right-radius: 8px;
	 -webkit-border-top-left-radius: 20px;
	 -webkit-border-bottom-left-radius: 20px;
	 -moz-border-radius-topleft: 20px;
	 -moz-border-radius-bottomleft: 20px;
	 border-top-left-radius: 20px;
	 border-bottom-left-radius: 20px;
	 background-color: #2bc253;
	 background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #2bc253), color-stop(1, #54f054));
	 background-image: -moz-linear-gradient(center bottom, #2bc253 37%, #54f054 69%);
	 -webkit-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
	 -moz-box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
	 box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3), inset 0 -2px 6px rgba(0, 0, 0, 0.4);
	 position: relative;
	 overflow: hidden;
}
 .meter > span:after, .animate > span > span {
	 content: "";
	 position: absolute;
	 top: 0;
	 left: 0;
	 bottom: 0;
	 right: 0;
	 background-image: -webkit-gradient(linear, 0 0, 100% 100%, color-stop(0.25, rgba(255, 255, 255, .2)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, .2)), color-stop(0.75, rgba(255, 255, 255, .2)), color-stop(0.75, transparent), to(transparent));
	 background-image: -moz-linear-gradient(-45deg, rgba(255, 255, 255, .2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .2) 50%, rgba(255, 255, 255, .2) 75%, transparent 75%, transparent);
	 z-index: 1;
	 -webkit-background-size: 50px 50px;
	 -moz-background-size: 50px 50px;
	 -webkit-animation: move 2s linear infinite;
	 -webkit-border-top-right-radius: 8px;
	 -webkit-border-bottom-right-radius: 8px;
	 -moz-border-radius-topright: 8px;
	 -moz-border-radius-bottomright: 8px;
	 border-top-right-radius: 8px;
	 border-bottom-right-radius: 8px;
	 -webkit-border-top-left-radius: 20px;
	 -webkit-border-bottom-left-radius: 20px;
	 -moz-border-radius-topleft: 20px;
	 -moz-border-radius-bottomleft: 20px;
	 border-top-left-radius: 20px;
	 border-bottom-left-radius: 20px;
	 overflow: hidden;
}
 .animate > span:after {
	 display: none;
}
 @-webkit-keyframes move {
	 0% {
		 background-position: 0 0;
	}
	 100% {
		 background-position: 50px 50px;
	}
}
 .orange > span {
	 background-color: #f1a165;
	 background-image: -moz-linear-gradient(top, #f1a165, #f36d0a);
	 background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f1a165), color-stop(1, #f36d0a));
	 background-image: -webkit-linear-gradient(#f1a165, #f36d0a);
}
 .red > span {
	 background-color: #f0a3a3;
	 background-image: -moz-linear-gradient(top, #f0a3a3, #f42323);
	 background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f0a3a3), color-stop(1, #f42323));
	 background-image: -webkit-linear-gradient(#f0a3a3, #f42323);
}
 .nostripes > span > span, .nostripes > span:after {
	 -webkit-animation: none;
	 background-image: none;
}
 .meter .text-meter {
	 position: absolute;
	 right: 10px;
	 top: -5px;
	 color: #666;
}
 .meter .page-meter {
	 position: absolute;
	 left: 10px;
	 top: -5px;
	 color: #666;
}
 label[for="giftCode"] {
	 line-height: 51px;
	 vertical-align: baseline;
	 margin-right: 30px;
	 font-size: 16px;
	 background-color: #fff;
	 font-style: italic;
}
 input#giftCode {
	 line-height: 51px;
	 border: none;
	 border-bottom: 1px solid #777;
	 color: #00a7c9;
}
 input#giftCode.error {
	 color: red;
	 border-bottom: 1px solid red;
}
 .check-research-code-wrap {
	 padding-bottom: 50px;
}
 a.reset-button {
	 position: absolute;
	 top: -40px;
	 right: 0;
	 color: #888;
	 text-decoration: underline;
}
 .item-row .MuiFormControlLabel-root {
	 display: flex;
	 flex-direction: column-reverse;
}
 .question-label span.second {
	 display: block;
	 font-weight: 300;
}
.checkedIcon::before {
    display: block;
    width: 24px;
    height: 24px;
    background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg"><ellipse stroke="null" fill="%234a4a4a" ry="11.8767" rx="11.854035" id="svg_2" cy="12.158658" cx="12.067996" fill-opacity="null" stroke-opacity="null" stroke-width="null" /><path stroke="null" fill="%23ffffff" id="svg_1" d="m10.337262,18.768454l-5.80417,-6.276021l1.799938,-2.085226l3.970053,4.26781l7.874325,-9.035494l1.833473,2.046718l-9.673617,11.082212z"/></svg>');
    content: '';
}
 .app-msg {
	 font-size: 16px;
	 text-align: center;
	 background: #ccc;
	 border-radius: 8px;
}
 .PrivateSwitchBase-root-1 {
	 padding: 0 9px !important;
}
 .MuiTypography-body1 {
	 font-family: "Raleway" !important;
	 font-weight: inherit !important;
	 font-size: inherit !important;
}
 b, strong {
	 font-weight: bolder;
}
 label[for="education"] {
	 white-space: nowrap;
}
 @media (max-width: 768px) {
	 .question-label {
		 font-size: 16px;
		 padding-left: 20px;
		 width: 70%;
		 display: flex;
	}
	 .question-label span.second {
		 margin-left: 5px;
	}
	 .row-labels .col {
		 padding: 5px 3px;
		 display: flex;
		 flex-direction: row-reverse;
		 justify-content: space-evenly;
		 flex: 1 0 100%;
	}
	 .questionsHeader.sticky {
		 top: 17px;
	}
	 .instructions-text p {
		 text-align: left;
	}
	 .intro-text p {
		 text-align: left;
	}
	 .final-text p {
		 text-align: left;
	}
	 .instruction-first-p {
		 text-align: left;
	}
	 input#giftCode {
		 line-height: 25px;
		 margin-bottom: 20px;
	}
	 p {
		 text-align: left;
	}
	 li.prev-item {
		 list-style-type: none;
	}
	 a.button {
		 font-size: 0.8em;
	}
	 #footer-inside #toTop {
		 display: none !important;
	}
	 label[for="education"] {
		 white-space: initial;
	}
	 .prev-item > a, .prev-item > button, .prev-item > button.auto {
		 width: 100%;
	}
}
 .factorsHolder {
	 list-style-type: none;
	 margin-top: 15px;
	 padding-left: 15px;
}
 